import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import parseHtml from '../parseHtml/parseHtml';
import styled from 'styled-components'

import { LineChart, Line, Legend, Area, AreaChart, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';



const data = [
  {
    name: '19Q2',
    'NS Quant': 2400,
    'Long only comparison': 4000,
    amt: 2400,
  },
  {
    name: '19Q3',
    'NS Quant': 3900,
    'Long only comparison': 1300,
    amt: 2210,
  },
  {
    name: '19Q4',
    'NS Quant': 9800,
    'Long only comparison': 2000,
    amt: 2290,
  },
  {
    name: '20Q1',
    'NS Quant': 3900,
    'Long only comparison': 2700,
    amt: 2000,
  },
  {
    name: '20Q2',
    'NS Quant': 4800,
    'Long only comparison': 1900,
    amt: 2181,
  },
  {
    name: '20Q3',
    'NS Quant': 3800,
    'Long only comparison': 2400,
    amt: 2500,
  },
  {
    name: '20Q4',
    'NS Quant': 4300,
    'Long only comparison': 3500,
    amt: 2100,
  },
];


const Ahover = styled.div`
  a { text-decoration:none; color:black; display:inline-block; padding-bottom:0; border-bottom:2px solid rgba(0,0,0,0); }
  a:hover { border-bottom:2px solid rgb(32, 190, 225); }
`;

const Graph = props => {
  let title = props.page.title
  if ( props.title !== undefined ) {
    title = props.title
  }
  //console.log( 'Generic props.structure', props.structure )
  return (
  <div className="products-wrapper" style={{ 
    backgroundColor:props.structure.backgroundColor || '#fff', 
    color:props.structure.color || '#000', 
    padding:'0', 
    boxSizing:'border-box', 
  }} >
    <div className="products" style={{ maxWidth:'900px' }}  >
      { title && 
      <h1 style={{ padding:'30px 30px 10px 30px', margin:'0', boxSizing:'border-box' }}>{ parseHtml( title  || '' ) }</h1>
      }

      {/*      
      <Ahover style={{ padding:'10px 30px', margin:'0', fontSize:'15px', boxSizing:'border-box' }}>
        { parseHtml( props.structure.column || '' ) }
      </Ahover>
      */}

      <div style={{ width:'100%', height:'480px', padding:'20px 0' }} >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="NS Quant" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="Long only comparison" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
    </div>



    </div>
  </div>
  )
}
export default Graph
