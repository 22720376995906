import React, { Fragment, useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, useHistory, Route, Redirect } from 'react-router-dom'; // must import Router, else switch doesn't work
import Store from '../Store/Store';

import Login from '../Login/Login';

import LongScroll from '../LongScroll/LongScroll';
import BlogPage from '../Blogs/BlogPage';
import NewsPage from '../News/NewsPage';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
//import Cookies from '../Cookies/Cookies';
import Background from '../Fiber/Background';
import '../css/index.css';

const Logout = props => {

  let history = useHistory();
  const [ tmp, setTmp ] = useState( 0 );

  return (
    <div 
      style={{ position:'fixed', cursor:'pointer', userSeöect:'none', verticalAlign:'top', bottom:0, right:0, padding:'0px 10px 0 15px', margin:'20px', background:'rgba(255,255,255,0.8)', borderRadius:'20px', color:'#000', fontWeight:'700', fontSize:'12px', boxShadow:'0 2px 4px rgba(0,0,0,0.3)' }}
      onClick={ () => { props.setUser( null ); } }
    >
      <div style={{ display:'inline-block', padding:'0 5px 0 0', transform:'translateY(-3px)' }} >
      { props.name }
      </div>
      <div style={{ display:'inline-block', padding:'0', transform:'translateY(4px)', fontSize:'12px' }} >
        <span className="material-icons">{ "exit_to_app" }</span>
      </div>

    </div>
  )
}

const App = ( props ) => {

  const [ tmp, setTmp ] = useState( 0 );
  const [ offset, setOffset ] = useState(0);
  const [ user, setUser ] = useState( null );
  const [ lang, setLang ] = useState( Store.get( 'lang' ) )
  
  useEffect(() => {
    window.onscroll = () => {
      setOffset( window.pageYOffset )
    }
  }, []);

  //let user = Store.get( 'user' )
  //console.log( 'app render user', user )
  //console.log( 'app again' )

/*
  if ( !user ) {
    return (
      <Login setUser={ setUser } />
    )  
  } else {
*/

  return (
    <Fragment>
      <Router basename={ process.env.PUBLIC_URL }>
        <Fragment>
        <Switch>
          <Fragment>

            <Route exact path="/" render={ routeProps => <LongScroll { ...routeProps } section="front" />} />
            <Route exact path="/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="front" />} />
            <Route exact path="/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="front" />} />

            {/*
            <Route exact path="/funds/" render={ routeProps => <LongScroll { ...routeProps } section="funds" />} />
            <Route exact path="/funds/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="funds" />} />
            <Route exact path="/funds/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="funds" />} />

            <Route exact path="/partners/" render={ routeProps => <LongScroll { ...routeProps } newslimit="200" section="partners" />} />
            <Route exact path="/partners/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="partners" />} />
            <Route exact path="/partners/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="partners" />} />

            <Route exact path="/services/" render={ routeProps => <LongScroll { ...routeProps } section="services" />} />
            <Route exact path="/services/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="services" />} />
            <Route exact path="/services/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="services" />} />

            <Route exact path="/contact/" render={ routeProps => <LongScroll { ...routeProps } section="contact" />} />
            <Route exact path="/contact/:slug/" render={ routeProps => <LongScroll { ...routeProps } section="contact" />} />
            <Route exact path="/contact/:slug/:sub/" render={ routeProps => <LongScroll { ...routeProps } section="contact" />} />

            <Route exact path="/blogs/:slug/" component={ BlogPage }/>
            */}

            <Redirect from="*" to="/" />
          </Fragment>

        </Switch>

        <Fragment>
          <Footer lang={ lang } />
          <Route path="/:route/" render={ routeProps => <Header { ...routeProps } lang={ lang } setLang={ setLang } offset={ offset } />} />
          <Route exact path="/" render={ routeProps => <Header { ...routeProps } lang={ lang } setLang={ setLang } offset={ offset } />} />
          {/*
          <Logout setUser={ setUser } name={ user.last_name + ' ' + user.first_name } />
          */}
        </Fragment>

        </Fragment>
      </Router>
    </Fragment>
  ) 
  }
//}

export default App 
