import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Link } from 'react-router-dom'; 
import styled from 'styled-components'
import bgImage from '../img/dedalos-logo-black.png';
import bgImageWhite from '../img/dedalos-logo-white.png';
import ActionButton from '../ActionButton/ActionButton';
import Store from '../Store/Store'

//import '../css/index.css';

const ShowOnlyOnDesktop = styled.div`
  display: none;
  @media only screen and ( min-width: 1037px) {
    display: block;
  }
`;

const ShowOnlyOnMobile = styled.div`
  display: none;
  @media only screen and ( max-width: 1036px) {
    display: block;
  }
`;

const SectionsDesktop = props => {

  let color = props.top ? 'white' : 'black' 
  let underLine = props.top ? '32,190,225' : '32,190,225' 

  return (
    <div style={{ display:'flex', userSelect:'none', margin:'22px 0 0 0' }} >

      <div className={ props.route === 'funds' ? 'header-underline-active' : 'header-underline' } >
        <Link draggable="false" to="/funds/" style={{ color:color }} >
          FUNDS
        </Link>
      </div>

      <div className={ props.route === 'partners' ? 'header-underline-active' : 'header-underline' } >
        <Link draggable="false" to="/partners/" style={{ color:color }} >
          ASSET MANAGEMENT
        </Link>
      </div>

      <div className={ props.route === 'services' ? 'header-underline-active' : 'header-underline' } >
        <Link draggable="false" to="/services/" style={{ color:color }} >
          SERVICES
        </Link>
      </div>

      <div className={ props.route === 'contact' ? 'header-underline-active' : 'header-underline' } >
        <Link draggable="false" to="/contact/" style={{ color:color }} >
          ABOUT US
        </Link>
      </div>

    </div>
  )  
}


const ChooseLanguage = props => {

  return (
    <div style={{ display:'flex', whiteSpace:'nowrap', transform:'translateY(-10px)' }} >

      <div onClick={ () => { props.setLang( 'fi' ); Store.set( 'lang', 'fi' ); } } className="header-underline" style={{ cursor:'pointer', height:'0.9rem', marginTop:'10px', marginRight:'0', opacity:props.lang === 'fi' ? '1.0' : '0.5', fontSize:'0.75rem', color:props.color }} >
          &nbsp;FI&nbsp;
      </div>
      <div className="header-underline" style={{ height:'0.9rem', marginTop:'10px', marginRight:'0', opacity:'0.3', fontSize:'0.75rem', color:props.color }} >
          |
      </div>
      <div onClick={ () => { props.setLang( 'en' ); Store.set( 'lang', 'en' ); } } className="header-underline" style={{ cursor:'pointer', height:'0.9rem', marginTop:'10px', marginRight:'0', opacity:props.lang === 'en' ? '1.0' : '0.5', fontSize:'0.75rem', color:props.color }} >
          &nbsp;EN&nbsp;
      </div>
      <div className="header-underline" style={{ height:'0.9rem', marginTop:'10px', marginRight:'0', opacity:'0.3', fontSize:'0.75rem', color:props.color }} >
          |
      </div>
      <div onClick={ () => { props.setLang( 'se' ); Store.set( 'lang', 'se' ); } } className="header-underline" style={{ cursor:'pointer', height:'0.9rem', marginTop:'10px', marginRight:'0', opacity:props.lang === 'se' ? '1.0' : '0.5', fontSize:'0.75rem', color:props.color }} >
          &nbsp;SE&nbsp;
      </div>

    </div>
  )
}

const Menu = props => {
  return(
    <ShowOnlyOnMobile>
    { props.menu 
      ? <div 
          style={{ position:'fixed', cursor:'pointer', left:0, top:0, width:'100%', height:'100%', textAlign:'right', color:'black', backgroundColor:'rgba(0,0,0,0.25)' }} 
          onClick={ () => props.setMenu( false ) }
        >
          <div style={{ position:'absolute', right:'-5px', top:'-5px', margin:0, width:'240px', height:'400px', borderRadius:'8px', background:'white', color:'#000', boxShadow:'0 0 8px 1px rgba(0,0,0,0.25)',  }} >

            <div style={{ opacity:'100%', userSelect:'none', position:'absolute', right:'30px', top:'25px' }} >
              <span className="material-icons">close</span>
            </div>

            <div 
              style={{ opacity:'100%', cursor:'pointer', userSelect:'none', position:'absolute', right:'34px', top:'60px', fontSize:'0.8rem', lineHeight:'40px', fontWeight:'700' }} 
            >

              <div style={{ padding:'5px' }} />

              {/*
              <Link draggable="false" to="/funds/" >
                <div>FUNDS</div>
              </Link>
              <Link draggable="false" to="/partners/" >
                <div>PARTNERS</div>
              </Link>
              <Link draggable="false" to="/services/" >
                <div>SERVICES</div>
              </Link>
              <Link draggable="false" to="/contact/" >
                <div>ABOUT US</div>
              </Link>
              */}

              <div style={{ padding:'8px' }} />

              <div style={{ color:'black' }} >
                SUOMEKSI
              </div>

              <div style={{ color:'black' }} >
                IN ENGLISH
              </div>

            </div>

          </div>

        </div>

      : <Fragment>
          <div 
            style={{ opacity:'100%', cursor:'pointer', userSelect:'none', position:'absolute', color:props.color, right:'25px', top:'20px' }} 
            onClick={ () => props.setMenu( true ) }
          >
            <span className="material-icons">menu</span>
          </div>
        </Fragment>
    }
    
    </ShowOnlyOnMobile>

  )
}

const Header = props => {

  let route = props && props.match && props.match.params ? props.match.params.route : null

  const [ menu, setMenu ] = useState( false )

  useEffect( () => {
  }, [] )

  let top = props.offset < 100 
  let color = top ? 'white' : 'black' 
  //let underLine = top ? '32,190,225' : '32,190,225' 
  let bgUrl = top ? `${ bgImageWhite }` : `${ bgImage }`

  return (
    <Fragment>
    <div style={{ position:'fixed', left:0, top:0, width:'100%', height:'60px', boxSizing:'border-box' }} >

      <div style={{ position:'fixed', left:0, top:0, width:'100%', height:'60px', boxSizing:'border-box', boxShadow:!top && '0 2px 16px rgba(0,0,0,0.25)', background:!top ? 'white' : 'none' }} />
        <div className="products" style={{ position:'relative', width:'100%', maxWidth:'900px', margin:'auto', textAlign:'left', fontSize:'0.75rem', lineHeight:'14px', fontWeight:'700', whiteSpace:'nowrap', userSelect:'none' }} >

          <div style={{ position:'absolute', top:0, left:0, margin:'16px 0 0 30px', fontSize:'18px', textAlign:'center' }} >
            <Link draggable="false" to="/funds/" style={{ color:color, padding:'0' }} >
              <div style={{ fontSize:'18px', padding:'0 0 0 0' }} >SELMA</div>
              <div style={{ fontSize:'12px', padding:'2px 0 0 0' }} >ADVISORS</div>
            </Link>
          </div>

          {/*
          <ShowOnlyOnDesktop style={{ position:'absolute', left:'50%', top:0, transform:'translateX(-50%)', margin:'0 100px 0 0' }} >
            <SectionsDesktop top={ top } route={ route } />
          </ShowOnlyOnDesktop>

          <ShowOnlyOnDesktop style={{ position:'absolute', top:0, right:0, whiteSpace:'nowrap', margin:'22px 30px 0 0' }} >
            <ChooseLanguage top={ top } color={ color } lang={ props.lang } setLang={ props.setLang }  />
          </ShowOnlyOnDesktop>

          */}

          <div style={{ position:'absolute', top:0, right:0, whiteSpace:'nowrap', margin:'22px 30px 0 0' }} >
            <ChooseLanguage top={ top } color={ color } lang={ props.lang } setLang={ props.setLang }  />
          </div>



      </div>
    </div>
    </Fragment>
  )  
}

export default Header
