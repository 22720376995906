import Request from '../Request/Request';
import Store from '../Store/Store';

const Data = () => {

	const fetch = callBack => {
		Request.get( 'data', 
		result => { 
			let blogs = result.data.blogs
			blogs.forEach( ( r, i ) => {
				r.productListId = r.productlist_id
			} ) 
			Store.set( 'blogs', blogs )
			let pages = result.data.pages
			let pagesObject = {}
			pages.forEach( ( r, i ) => {
				if ( !pagesObject.hasOwnProperty( r.section ) ) {
					pagesObject[ r.section ] = []
				}
				pagesObject[ r.section ].push( r )
			} ) 
			Store.set( 'pages', pages )
			Store.set( 'pagesObject', pagesObject )

			let news = result.data.news
			Store.set( 'news', news )

			let employees = result.data.employees
			Store.set( 'employees', employees )

			let companies = result.data.companies
			Store.set( 'companies', companies )

			let data = { blogs:blogs, pages:pages, pagesObject:pagesObject, news:news, employees:employees, companies:companies }
			//console.log( 'fetch data', data )

			callBack( data )

		} )

	}

	return { 
		fetch:fetch, 
		blogs:Store.get( 'blogs' ) || [], 
		pages:Store.get( 'pages' ) || [], 
		pagesObject:Store.get( 'pagesObject' ) || [], 
		news:Store.get( 'news' ) || [], 
		employees:Store.get( 'employees' ) || [], 
		companies:Store.get( 'companies' ) || [], 
	}


}

export default Data
