import React, { useRef } from "react"
import { useFrame, invalidate } from "react-three-fiber"
//import { useGLTF } from "@react-three/drei"
import { DoubleSide } from 'three';

export default function Icosahedrons(props) {

  const first = useRef()
  const red = useRef()
  const green = useRef()
  const blue = useRef()
  const last = useRef()

  useFrame( ( state ) => {
    if ( blue.current === null ) return

    if ( window.pageYOffset !== 0 ) {
      invalidate()
      return  
    }

    //const t = state.clock.getElapsedTime()
    //red.current.rotation.z = 0 + Math.sin( t / 2 ) / 1
    //green.current.rotation.z = 0 + Math.sin( t / 2 ) / 1
    //blue.current.rotation.z = 0 + Math.sin( t / 2 ) / 1
    const speed = 0.0004
    first.current.rotation.y += speed
    red.current.rotation.y += speed + 0.00001
    green.current.rotation.y += speed + 0.00002
    blue.current.rotation.y += speed + 0.00003
    last.current.rotation.y += speed + 0.00004

    first.current.rotation.x += speed + 0.00004
    red.current.rotation.x += speed + 0.00003
    green.current.rotation.x += speed + 0.00002
    blue.current.rotation.x += speed + 0.00001
    last.current.rotation.x += speed

  })

  return (
    <group 
      { ...props } 
      dispose={ null }
    >

    <mesh ref={ first } position={[ -1, 0, 0 ] } rotation={[ 0.785, 0, 0.785 ] } castShadow receiveShadow >
      <icosahedronGeometry attach="geometry" args={[ 0.475, 0 ]} />
      <meshStandardMaterial 
        color="#607d8b" attach="material" side={ DoubleSide } 
        roughness={ 0.15 } metalness={ 0.8 } transparent={ true } opacity={ 0.75 }
      />
    </mesh>

    <mesh ref={ red } position={[ -0.5, 0, 0 ] } rotation={[ 0.785, 0, 0.785 ] } castShadow receiveShadow >
      <icosahedronGeometry attach="geometry" args={[ 0.475, 0 ]} />
      <meshStandardMaterial 
        color="#78909c" attach="material" side={ DoubleSide }  
        roughness={ 0.15 } metalness={ 0.8 } transparent={ true } opacity={ 0.75 }
      />
    </mesh>

    <mesh ref={ green } position={[ 0, 0, 0 ] } rotation={[ 0.785, 0, 0.785 ] } castShadow receiveShadow >
      <icosahedronGeometry attach="geometry" args={[ 0.475, 0 ]} />
      <meshStandardMaterial 
        color="#607d8b" attach="material" side={ DoubleSide } 
        roughness={ 0.15 } metalness={ 0.8 } transparent={ true } opacity={ 0.75 }
      />
    </mesh>

    <mesh ref={ blue } position={[ 0.5, 0, 0 ] } rotation={[ 0.785, 0, 0.785 ] } castShadow receiveShadow >
      <icosahedronGeometry attach="geometry" args={[ 0.475, 0 ]} />
      <meshStandardMaterial 
        color="#78909c" attach="material" side={ DoubleSide } 
        roughness={ 0.15 } metalness={ 0.8 } transparent={ true } opacity={ 0.75 }
      />
    </mesh>

    <mesh ref={ last } position={[ 1, 0, 0 ] } rotation={[ 0.785, 0, 0.785 ] } castShadow receiveShadow >
      <icosahedronGeometry attach="geometry" args={[ 0.475, 0 ]} />
      <meshStandardMaterial 
        color="#607d8b" attach="material" side={ DoubleSide } 
        roughness={ 0.15 } metalness={ 0.8 } transparent={ true } opacity={ 0.75 }
      />
    </mesh>

    </group>
  )
}
